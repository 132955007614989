<template>
  <v-card flat>
    <v-expand-transition>
      <div v-if="!sent">
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="12">
                Enter your username (usually the email address you signed up with) and we will send a "Magic Link" to your primary email address.  This link will sign you in directly,
                and allow you to reset your password.
                <strong>The link will only be valid for 15 minutes</strong>, so make sure you have access to your email.
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                If you have a SPAM filter, go ahead and add MagicLink@VolleyballLife.com to the safe sender list.
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-form>
                  <v-text-field
                    label="Username"
                    v-model="myUsername"
                    :rules="$v.$dirty ? [
                      () => $v.myUsername.required || 'An email is required',
                    ] : []"
                    required
                    @blur="$v.myUsername.$touch()"
                    color="color3"
                  ></v-text-field>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer>
          </v-spacer>
            <v-btn
              text
              color="color3"
              :disabled="$v.$invalid || sending"
              :loading="sending"
              @click="sendLink"
            >Send me the link</v-btn>
            <v-btn
              text
              :disabled="sending"
              :loading="sending"
              @click.stop="$emit('back-click')">Cancel</v-btn>
        </v-card-actions>
      </div>
    </v-expand-transition>
    <v-expand-transition>
      <div v-if="sent">
        <v-card-text>
          <v-container>
            <v-row dense>
              <h2>Your Magic Link is on it's way!</h2>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                We just sent a "Magic Link" to {{sentTo}}.  Clicking the link will sign you in directly
                and allow you to reset your password.
                <strong>The link will only be valid for 15 minutes</strong>, so check your email now.
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                If you have a SPAM filter, go ahead and add MagicLink@VolleyballLife.com to the safe sender list.
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="color3"
            @click.stop="$emit('back-click')"
          >Ok</v-btn>
          <v-btn
            text
            :disabled=" sending"
            :loading="sending"
            @click.stop="reset">Send a new link</v-btn>
        </v-card-actions>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  props: ['username', 'active', 'returnUrl'],
  mixins: [validationMixin],
  data () {
    return {
      dialog: false,
      myUsername: null,
      sending: false,
      sent: false,
      sentTo: null,
      errors: []
    }
  },
  validations () {
    return {
      myUsername: { required }
    }
  },
  methods: {
    reset () {
      this.sent = false
      this.sentTo = null
    },
    sendLink () {
      if (this.$v.$invalid) return
      this.sending = true

      this.$VBL.user.sendMagicLink({
        username: this.myUsername,
        returnUrl: this.returnUrl
      })
        .then((response) => {
          this.sentTo = response.data
          this.sending = false
          this.sent = true
        })
        .catch((error) => {
          console.log(error.response)
          this.sending = false
        })
    }
  },
  watch: {
    active: function (val) {
      if (val) {
        if (this.username) this.myUsername = this.username
        this.$v.$reset()
      } else {
        this.myUsername = null
        this.sent = false
        this.sentTo = null
      }
    }
  }
}
</script>

<style scoped>
  .link {
    cursor: pointer;
  }
</style>
