<template>
  <v-card flat>
    <v-toolbar color="color2 color2Text--text" dark>
      <v-toolbar-title v-if="passwordMode === 'Change'">Change your password</v-toolbar-title>
      <v-toolbar-title v-else>Set a password</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form>
        <v-text-field
          v-if="passwordMode === 'Change'"
          label="Current password*"
          v-model="oldPassword"
          hint="At least 6 characters"
          min="6"
          :append-icon="hideOld ? 'fal fa-eye' : 'fal fa-eye-slash'"
          @click:append="() => (hideOld = !hideOld)"
          :type="hideOld ? 'password' : 'text'"
          :error-messages="oldPasswordErrors"
          color="color3"
          @blur="$v.oldPassword.$touch()"
        ></v-text-field>
        <v-text-field
          label="New password*"
          v-model="newPassword"
          hint="At least 6 characters"
          min="6"
          :append-icon="hideNew ? 'fal fa-eye' : 'fal fa-eye-slash'"
          @click:append="() => (hideNew = !hideNew)"
          :type="hideNew ? 'password' : 'text'"
          :error-messages="newPasswordErrors"
          @blur="$v.newPassword.$touch()"
          color="color3"
        ></v-text-field>
        <v-text-field
          label="Re-type new password*"
          v-model="confirmPassword"
          hint="At least 6 characters"
          min="6"
          :append-icon="hideConfirm ? 'fal fa-eye' : 'fal fa-eye-slash'"
          @click:append="() => (hideConfirm = !hideConfirm)"
          :type="hideConfirm ? 'password' : 'text'"
          :error-messages="confirmPasswordErrors"
          @blur="$v.confirmPassword.$touch()"
          color="color3"
        ></v-text-field>
      </v-form>
      <div v-for="(error,i) in errors" :key="i" class="text-center red--text darken4">{{error}}</div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        text
        color="color3"
        :loading="loading"
        :disabled="$v.$invalid"
        @click="save"
      >Save Changes</v-btn>
      <v-btn
        text
        :loading="loading"
        @click="closeMe"
      >Cancel</v-btn>
    </v-card-actions>
    <v-card-text class="text-center caption" v-if="passwordMode === 'Changex'">
      <forgot-password :username="this.signIns.username"></forgot-password>
    </v-card-text>
  </v-card>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import ForgotPassword from './ForgotPassword.vue'
import * as mutations from '../../store/MutationTypes'
import { mapGetters } from 'vuex'

export default {
  props: ['signIns'],
  mixins: [validationMixin],
  validations () {
    return {
      oldPassword: this.passwordMode === 'Change' ? { required, minLength: minLength(6) } : {},
      newPassword: { required, minLength: minLength(6) },
      confirmPassword: { required, sameAsNew: sameAs('newPassword') }
    }
  },
  data () {
    return {
      oldPassword: null,
      hideOld: true,
      newPassword: null,
      hideNew: true,
      confirmPassword: null,
      hideConfirm: true,
      loading: false,
      errors: []
    }
  },
  computed: {
    ...mapGetters(['user']),
    passwordMode () {
      if (this.signIns && !this.signIns.password) return 'Set'
      if (this.user && this.user.canSetPassword) return 'Set'

      const expire = this.$store.getters.dtMagicLinkExpires
      if (expire) {
        if (expire.isAfter()) {
          this.$store.commit(mutations.SET_MAGICLINKTIME, null)
          return 'Change'
        }
        return 'Set'
      }
      return 'Change'
    },
    oldPasswordErrors () {
      const errors = []
      if (!this.$v.oldPassword.$dirty || !this.$v.oldPassword.$invalid) return errors
      !this.$v.oldPassword.required && errors.push('A password is required')
      !this.$v.oldPassword.minLength && errors.push('Your password must be at least 6 characters')
      !/\d/.test(this.oldPassword) && errors.push('Your password must have at least one digit (0-9).')
      return errors
    },
    newPasswordErrors () {
      const errors = []
      if (!this.$v.newPassword.$dirty) return errors
      !this.$v.newPassword.required && errors.push('A password is required')
      !this.$v.newPassword.minLength && errors.push('Your password must be at least 6 characters')
      !/\d/.test(this.newPassword) && errors.push('Your password must have at least one digit (0-9).')
      return errors
    },
    confirmPasswordErrors () {
      const errors = []
      if (!this.$v.confirmPassword.$dirty) return errors
      !this.$v.confirmPassword.required && errors.push('You must re-type your password')
      !this.$v.confirmPassword.sameAsNew && errors.push('Your new password does not match')
      return errors
    },
    dto () {
      return {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
        confirmPassword: this.confirmPassword
      }
    }
  },
  methods: {
    closeMe () {
      this.$emit('close')
      this.oldPassword = null
      this.hideOld = true
      this.newPassword = null
      this.hideNew = true
      this.confirmPassword = null
      this.hideConfirm = true
      this.error = []
      this.$v.$reset()
    },
    save () {
      this.loading = true
      this.errors = []
      const model = this.dto
      this.$VBL.user.changePassword(model)
        .then(r => {
          console.log('pre')
          this.$store.commit(mutations.SET_PASSWORDSET_DT)
          this.closeMe()
          this.$emit('pw-set')
        })
        .catch((error) => {
          console.log(error.response)
          this.errors = error.response.data.errors
        })
        .finally(() => { this.loading = false })
    }
  },
  components: {
    'forgot-password': ForgotPassword
  }
}
</script>

<style>

</style>
